import React from "react";
import ReactDOM from "react-dom";
import Preloader from "./components/Preloader/Preloader";
import Optin from "./components/Optin/Optin";
import { Helmet } from "react-helmet";
import "./styles.css";

function App() {
  return (
    <div className="App">
      <Helmet>
        <html lang="en" />
        <title>Unreal Discord</title>
        <meta
          name="description"
          content="Unreal Discord Community, We Talk Unreal things. Games and Films development using Unreal Engine, Unity Engine, Cry Engine, Maya, 3DsMax and Blender."
        />
        <meta name="theme-color" content="#E6E6FA" />
      </Helmet>
      <div className="logo">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="#fff"
          width="150px"
          height="150"
          viewBox="0 0 918.6 748.2"
        >
        <g>
            <path d="M481.8,896c-13.3-2.1-26.5-3.9-39.7-6.1C291.7,864,166.5,743.7,136.6,593.4,109.7,457.8,145,338.9,242.4,240.5c61.3-62,136.7-97.8,223.5-107.4,116.9-13,219.9,20.2,306.2,100C847.4,302.7,889,390,893.3,492.5c5.9,140.9-50.2,253.4-164.6,335.8-54.6,39.1-116.5,60.3-183.5,66.4A14.7,14.7,0,0,0,541,896Zm28.5-14.3c202.8,0,368.8-164.7,369.5-365.8.7-205.9-164.4-367.3-361.4-371.1-203-4-376,158.7-375.9,368.6C142.6,716.9,307.9,881.7,510.3,881.7Z" transform="translate(-41.9 -130.4)"/>
            <path d="M981.9,598.7c-1.2,11.5-2.3,22.8-3.5,34.3-.4,4.1-1.1,8.2-1.7,13.3-5.1-9.9-9-18.9-14.1-27.1s-10.5-10-16.9-.9c-21.6,30.8-42.7,62-63.6,93.2-6.2,9.1-11.4,18.8-18.6,27.7,2.8-8.2,5.6-16.2,8.1-24.3,20.2-63.7,31.8-128.8,32.2-195.6.6-86.5-23.6-166-69-239.3-24.6-39.6-52.9-76.1-86.4-108.5a8.2,8.2,0,0,1-1.2-2l1.4-1.9c21,13.4,42.6,26.1,63.1,40.3,39.7,27.7,74.5,60.7,103.4,99.8,36.7,49.4,54.1,105.9,61.4,166,2.1,16.5,3.6,33.1,5.4,49.6Z" transform="translate(-41.9 -130.4)"/>
            <path d="M47,375.2c5.4,10.1,9.3,19.2,14.7,27.2s10,8.8,15.8.7c20.2-28.6,40-57.3,59.5-86.4,7.9-11.8,14.8-24.3,22.8-36-6.1,20.5-12.8,40.9-18.2,61.6-17.2,65.5-25.3,132-20.1,199.6,6,77.5,33.4,147.5,75.7,212.1a545.7,545.7,0,0,0,74.4,91.7,12.3,12.3,0,0,1,3.7,6.3c-7.9-4.4-16-8.6-23.9-13.3C208,813,168.7,782,134.3,744.8,83.5,690,56.8,624.6,47.4,551.1c-7-56.2-7-112.6-1.7-169C45.9,380,46.4,378.2,47,375.2Z" transform="translate(-41.9 -130.4)"/>
            <path d="M369.8,739.2l-6.4-13.4a33.1,33.1,0,0,0-40.5-17c-5.2,1.8-10.4,3.6-15.5,5.7-6.9,2.8-11.9,2.6-18.2-1.6-24.2-16.3-46.4-34.6-62.8-59.2-7.8-11.5-13.8-23.8-16.5-37.8,4.2,1.8,7.9,3.6,11.7,5.1,7.2,2.9,11.4,1.7,15.7-4.7a41.1,41.1,0,0,0,2.7-5.1q24-54.2,47.9-108.4c4.3-9.8-.4-20-11-21.3a58.9,58.9,0,0,0-22.1,1.8c-31.9,8.9-59.9,25.6-86.5,44.8l-5.7,4a77.9,77.9,0,0,1,12.9-27.3c14.9-20.7,34.7-35.7,56.7-47.9,45.7-25.2,94.2-40.6,146.8-41.3,9.5-.1,19,.9,29.4,1.5-9.2,3.3-17.4,5.7-25.2,9-15.1,6.8-23.6,19.2-28.2,34.6-2.2,7.6-.9,13.4,3.8,16,2.6,1.5,5.9,1.9,9.6,2.9L306.8,619.3c-6.2,14.1,1.7,29.8,17,33.3,12.8,3,25-.1,37-3.9,1.4-.3,2.5-2.2,3.2-3.6,17.5-39.5,35.2-78.9,52.1-118.6,4.3-10.1,2.6-20.9-1.9-31.1-.7-1.7-1.6-3.4-2.9-6,9.1,3.2,16.4,7.7,22.3,14,3.2,3.3,5.8,3.5,10,1.8,34.1-13.5,69.4-14.3,105.1-8.1a19.6,19.6,0,0,1,3.4,1.2l-4.6,2.6c-16.2,8.8-32.3,18-48.7,26.6-10.1,5.3-16.5,12.9-21,23.4-17.5,41.3-35.4,82.4-53.1,123.6-2.8,6.5,1.2,13.5,9.5,15.6a53.9,53.9,0,0,0,15,1.6c16.8-.4,32.7-5.3,48-11.8C461.3,713.2,417.9,730.6,369.8,739.2Z" transform="translate(-41.9 -130.4)"/>
          </g>
          <g>
            <path d="M580.5,354.6c23.6-.9,45.8-6.4,67.3-15,19.9-7.9,38.6-18.1,54.4-32.4,17.7-16.2,16.2-17,4.2-33.7-25.3-35.2-59-60.8-97.9-79.2-22.5-10.7-46.4-18.5-67.9-26.9,7.3.9,16.9,1.8,26.5,3.3,57.7,9,110.8,29.2,157.5,64.9,14.5,11.1,27.9,23.4,38,38.8,9,13.5,8.9,18.3-1.4,30.9-23.6,28.5-53.5,48.8-86.9,63.6-58.1,25.7-118.3,32.5-180.2,15.4-22.9-6.4-42.9-17.9-57.9-37.1-23.2-29.8-25.6-74.8-5.6-108s58.4-50.9,95.1-44.6c40.2,6.9,70.5,35.6,78.3,74.8,5.5,28.1-1.3,53.5-18,76.4l-5.5,7.1C580.4,353,580.5,353.3,580.5,354.6Zm-69.4-13.7a53.4,53.4,0,0,0,53.6-53.5c.1-29.1-23.9-53.1-53.2-53.2a53.4,53.4,0,1,0-.4,106.7Z" transform="translate(-41.9 -130.4)"/>
            <path d="M461.7,393.9c-13.8-2.1-27.6-3.7-41.2-6.4-44.8-8.7-87.4-23.3-124.4-50.7a202.4,202.4,0,0,1-33-31.1c-11.3-13-10.6-19.4-.1-33,16.7-21.6,38.1-37.9,61-52.3a352.5,352.5,0,0,1,150.2-52.2,38.4,38.4,0,0,1,4.8,0c-13.9,4.9-27.9,9.3-41.4,14.9a313.5,313.5,0,0,0-111.5,78.6c-2.5,2.7-4.8,5.6-7.1,8.5-13.8,17.5-14,21.7-.1,39.6,16.8,21.7,38.4,38.1,62.9,49.8s48.8,20.3,73.3,30.2c2.3.9,4.6,1.5,7,2.2A12.9,12.9,0,0,0,461.7,393.9Z" transform="translate(-41.9 -130.4)"/>
          </g>
          <path d="M702.7,725c-1-1.3-1.8-2.7-2.9-3.9-25.6-26.6-51.3-53.1-76.7-79.8a12.8,12.8,0,0,1-3-7.8c-.3-8.4-.3-16.9.2-25.4.2-4.1-1.3-6.3-4.8-8.2-7.5-4.1-14.9-8.6-22.3-13.1a8,8,0,0,1-3.6-4.3Q574.8,525.2,560,467.1l21-5.8c29.8-8.2,59.5-16.3,89.4-24.2a11.7,11.7,0,0,1,8.2,1.1c7.7,4.1,15.2,8.8,22.6,13.5,2.5,1.6,4.3,1.4,6.7-.1,7.6-4.7,15.3-9.3,23.2-13.7a9.7,9.7,0,0,1,6.4-1.1c36.1,9.9,72.2,20.1,108.3,30.2a27.5,27.5,0,0,1,2.9,1.1c-6.1,23.2-12.2,46.1-18.2,69.1-4,14.9-7.8,29.9-12,44.8a10.9,10.9,0,0,1-4.2,5.5c-7.6,4.8-15.4,9.3-23.2,13.6-2.7,1.5-3.6,3.2-3.5,6.3.2,8.8-.1,17.6.1,26.4a10.3,10.3,0,0,1-3.3,8.3l-79.1,81a14.6,14.6,0,0,0-1.6,1.9ZM617.1,484.2l4.3,2.8c26.2,15.7,52.4,31.3,78.5,47.1,3.2,1.9,5.4,1.7,8.5-.1,24.7-14.8,49.6-29.5,74.4-44.2l8-4.8a20.5,20.5,0,0,0-2.6-1.3c-27.1-7.8-54.1-15.7-81.2-23.4a12.7,12.7,0,0,0-6.7.3c-13,3.5-25.9,7.2-38.8,10.9Zm188.1,26.1-2.9,1.3-81.4,48.1c-2.7,1.6-2.9,3.5-2.9,6.1q0,47.1-.2,94.3c0,1.3.2,2.7.3,4.6l2-1.6c20.2-20.3,40.3-40.5,60.4-60.9a13.8,13.8,0,0,0,3.1-6c5.1-19.9,10.1-39.9,15.1-59.8C800.9,527.9,802.9,519.4,805.2,510.3ZM688.9,665.7c.3-2.6.5-3.6.5-4.6.1-31.9.1-63.8.3-95.8,0-3.2-1.6-4.6-4-6.1l-78.6-47-4.2-2.2c.4,2,.6,3.5.9,4.9,6.8,27.1,13.4,54.1,20.3,81.2a15.8,15.8,0,0,0,3.7,6.7q26.3,27,52.7,53.8C683,659.2,685.4,661.9,688.9,665.7Z" transform="translate(-41.9 -130.4)"/>
          <g>
            <path d="M339.7,767.4l-19.6,29.1a45.2,45.2,0,0,1-5.1,6.6,9.8,9.8,0,0,1-5.2,2.5,16.9,16.9,0,0,1-7.6-.6,30.3,30.3,0,0,1-8.5-4.7,32,32,0,0,1-7.7-8,15.8,15.8,0,0,1-2.9-7.6,9.8,9.8,0,0,1,1.3-5.8,94.5,94.5,0,0,1,7.3-9.1l20.1-23.6c4.7,4,7.1,5.9,12,9.7l-24,31.1c-1.4,1.8-2.2,3.1-2.3,3.8s.2,1.3.9,1.9,1.7.9,2.4.5a16.5,16.5,0,0,0,3.3-3.7c7.7-10.3,15.5-20.6,23.2-31C332.2,762.2,334.7,764,339.7,767.4Z" transform="translate(-41.9 -130.4)"/>
            <path d="M352,786.3l-2.2,3.3a9.8,9.8,0,0,1,5.2-1.1,11.8,11.8,0,0,1,5.3,1.5,11.2,11.2,0,0,1,4.7,4.4,6.8,6.8,0,0,1,1,4.9,31.7,31.7,0,0,1-3,7.6l-13.5,26.9c-6-3-9-4.6-14.9-7.9l14.7-26a23.9,23.9,0,0,0,2.3-4.9c.1-.6-.2-1.2-1.1-1.7a2,2,0,0,0-2.4-.1,25.1,25.1,0,0,0-3.3,5c-4.9,8.3-9.8,16.7-14.6,25.1-5.9-3.4-8.8-5.2-14.5-8.9l23.4-36.1C344.2,781.6,346.8,783.2,352,786.3Z" transform="translate(-41.9 -130.4)"/>
            <path d="M386.7,803.7l-2.7,5c3.6-2.9,7.2-3.7,11-2.4l-5.5,14a15.9,15.9,0,0,0-6.1-1.4,4.1,4.1,0,0,0-3.3,1.6c-.8.9-2,3.3-3.6,7.1l-6.7,15.6c-6.1-2.6-9.1-4-15.2-6.9l18.8-38.7C378.7,800.2,381.3,801.4,386.7,803.7Z" transform="translate(-41.9 -130.4)"/>
            <path d="M423.4,839.8c-7.8-2-11.7-3.2-19.3-5.7l-3,8.8a7.4,7.4,0,0,0-.6,3.7c.1.6.7,1.1,1.6,1.4a2.5,2.5,0,0,0,2.7-.3,11.5,11.5,0,0,0,1.9-3.9c.7-2.2,1-3.3,1.7-5.4,5.7,1.8,8.5,2.6,14.3,4.1l-.9,3.1a26.8,26.8,0,0,1-2.1,5.7,11.6,11.6,0,0,1-4.1,3.7,14.1,14.1,0,0,1-6.6,1.9,26.2,26.2,0,0,1-8.9-1.5,29.9,29.9,0,0,1-8.4-4.1,15.3,15.3,0,0,1-4.8-5.2,11.1,11.1,0,0,1-1.2-5.4,25.3,25.3,0,0,1,1.8-7.1l4.5-11.7c1.4-3.5,3-6.1,4.9-7.7a12,12,0,0,1,7-2.8,21.9,21.9,0,0,1,8.9,1.3,22.5,22.5,0,0,1,9.1,5,12.8,12.8,0,0,1,3.9,6.8,24,24,0,0,1-1,9.8Zm-12.9-11,1-3a11.8,11.8,0,0,0,.9-4.2,1.8,1.8,0,0,0-1.3-1.5,2,2,0,0,0-2.1.2,14.6,14.6,0,0,0-1.7,4.1l-1,3Z" transform="translate(-41.9 -130.4)"/>
            <path d="M442.9,838.7c-5.6-1.2-8.4-1.8-13.9-3.2l.7-2.8a17.4,17.4,0,0,1,3.2-7.1c1.2-1.6,3.3-2.7,6.1-3.5a23.4,23.4,0,0,1,10.2.1c4.8.9,8.2,2.3,10.5,4.3a8.9,8.9,0,0,1,3.5,6.4c.2,2.3-.2,7-1.2,14-1.3,8.5-1.9,12.8-3.2,21.3-6.3-.9-9.4-1.4-15.7-2.6l.7-3.8a8.8,8.8,0,0,1-4.4,2.7,12.7,12.7,0,0,1-5.8-.1,15.2,15.2,0,0,1-7.3-3.6c-2-1.8-2.5-4.9-1.4-9.1.3-1.3.5-2,.8-3.4.8-3.1,2-5.1,3.4-5.9s4.7-1.5,9.6-2,8.1-1.2,8.6-1.7a6.7,6.7,0,0,0,1.2-3.4,12.2,12.2,0,0,0,.3-4.4,2.2,2.2,0,0,0-1.7-1.4c-1-.1-1.6,0-2,.5a18.7,18.7,0,0,0-1.3,4.3C443.5,836.1,443.3,836.9,442.9,838.7Zm3.6,6.7c-2.7,1.1-4.4,2-5,3a12.5,12.5,0,0,0-1.5,4.2,12.2,12.2,0,0,0-.3,4.4c.2.7.8,1.1,1.9,1.3s1.7.1,2-.3a12.7,12.7,0,0,0,1.3-3.9C445.5,850.7,445.9,848.9,446.5,845.4Z" transform="translate(-41.9 -130.4)"/>
            <path d="M485.5,818.5c-1.4,17.5-2.9,34.9-4.3,52.3-6.7-.6-10.1-.9-16.8-1.8,2.3-17.3,4.5-34.6,6.8-52C476.9,817.8,479.8,818.1,485.5,818.5Z" transform="translate(-41.9 -130.4)"/>
            <path d="M503.4,819.5h10.9c7.1-.1,11.8-.1,14.3.4a10.8,10.8,0,0,1,5.9,2.6,9.2,9.2,0,0,1,2.9,4.4,70.4,70.4,0,0,1,1.3,9.9c.6,7.3,1,11,1.6,18.3.4,4.7.4,7.8,0,9.4a6.9,6.9,0,0,1-2.6,4,13,13,0,0,1-4.9,2.2,52.8,52.8,0,0,1-9.1,1c-8.6.3-12.9.3-21.5.1Zm14.8,9c.3,11.5.5,23,.8,34.4,2.4,0,3.9-.5,4.4-1.3s.7-2.9.6-6.4c-.3-8.2-.5-12.2-.8-20.4a41.7,41.7,0,0,0-.4-4.5,2.3,2.3,0,0,0-1.2-1.5A7.7,7.7,0,0,0,518.2,828.5Z" transform="translate(-41.9 -130.4)"/>
            <path d="M556.4,816.2l1,6.7c-5.8.9-8.7,1.3-14.6,1.9-.3-2.7-.4-4.1-.7-6.8C547.9,817.4,550.7,817,556.4,816.2Zm1.4,9.3c2.2,14.2,4.3,28.4,6.4,42.5-6.7,1-10,1.5-16.7,2.1-1.5-14.2-2.9-28.5-4.4-42.7C549,826.8,551.9,826.4,557.8,825.5Z" transform="translate(-41.9 -130.4)"/>
            <path d="M595.4,831.8c-5.2,1.3-7.8,2-13.1,3.1l-.5-2.2q-.6-3-1.2-3.6c-.3-.5-1-.6-2-.4a2.4,2.4,0,0,0-1.7,1.1,3.4,3.4,0,0,0-.2,2.3,10.3,10.3,0,0,0,.9,2.7,4.8,4.8,0,0,0,2.3,1.6,38.4,38.4,0,0,0,7.1,1.7q7.2,1.2,9.9,3c1.8,1.2,3.1,3.3,3.9,6.4s1,6,.4,8a9.9,9.9,0,0,1-4.5,5.4,25.4,25.4,0,0,1-9.1,3.6,29.1,29.1,0,0,1-10.8.6c-3.1-.5-5.3-1.5-6.7-3.1s-2.4-4.1-2.9-7.6c-.2-.8-.2-1.2-.4-2.1,5.5-.9,8.3-1.4,13.7-2.5a26.1,26.1,0,0,1,.6,2.7c.5,2.2.9,3.7,1.4,4.3a2,2,0,0,0,2.3.5,2.9,2.9,0,0,0,2.2-1.2q.6-.7,0-3c-.4-2-1-3.2-1.7-3.5s-4-1.2-9.8-2.7-8-2.7-9.2-4.3a12.6,12.6,0,0,1-2.5-5.9q-.7-5.1.3-7.8a9.7,9.7,0,0,1,4.4-4.6,24.5,24.5,0,0,1,7.9-2.9,26.6,26.6,0,0,1,8.1-.7,13.2,13.2,0,0,1,5.7,1.5,7.7,7.7,0,0,1,2.9,2.7,18.4,18.4,0,0,1,1.6,4.5C595,830.3,595.1,830.8,595.4,831.8Z" transform="translate(-41.9 -130.4)"/>
            <path d="M633.1,822c-5.4,2.1-8.1,3.1-13.6,5-.6-1.9-.9-2.8-1.6-4.7a8.4,8.4,0,0,0-1.6-3.2,2.2,2.2,0,0,0-2-.3,1.8,1.8,0,0,0-1.3,1.4,9.2,9.2,0,0,0,.7,3.6c2.5,7.6,3.8,11.5,6.4,19.2a5.3,5.3,0,0,0,1.7,3,2.3,2.3,0,0,0,2.4.5,2.2,2.2,0,0,0,1.7-1.9,12.3,12.3,0,0,0-.9-4.5l-1.7-4.8c5.3-1.9,7.9-2.8,13.2-4.9a65.8,65.8,0,0,1,2.4,7.3,10.4,10.4,0,0,1-.6,5.9,13.7,13.7,0,0,1-4.2,6,23.9,23.9,0,0,1-8.3,4.3c-4.4,1.5-8.1,2-11.1,1.6a11,11,0,0,1-7-3.5c-1.7-1.9-3.1-4.8-4.2-8.9l-3.3-11.7a20.7,20.7,0,0,1-1-8.3c.2-2,1.5-4.1,3.6-6.3a22.5,22.5,0,0,1,8.6-5,22.1,22.1,0,0,1,9.9-1.2,11.2,11.2,0,0,1,7.1,3.2C630.1,815.5,631.6,818.2,633.1,822Z" transform="translate(-41.9 -130.4)"/>
            <path d="M668.4,804.2l5,9.3a38,38,0,0,1,3.5,7.8,11,11,0,0,1-.4,5.9,14.4,14.4,0,0,1-4,6,29.7,29.7,0,0,1-7.4,4.9,33.8,33.8,0,0,1-9,3,11.9,11.9,0,0,1-6.6-.3,10.1,10.1,0,0,1-4.5-3.1,27.5,27.5,0,0,1-4-7.3c-1.6-4-2.5-6.1-4.1-10.2s-2.3-6.7-2.2-9.2a11.6,11.6,0,0,1,2.7-7,22.2,22.2,0,0,1,8-5.9,27.5,27.5,0,0,1,8.2-2.6,15,15,0,0,1,6.5.5,12.5,12.5,0,0,1,4.4,2.6A20.3,20.3,0,0,1,668.4,804.2Zm-15.1,4.2a13.4,13.4,0,0,0-2.2-3.6,1.8,1.8,0,0,0-2,0q-1.2.6-1.2,1.5c-.1.7.4,2,1.3,4l8.2,17.6a11.5,11.5,0,0,0,2.1,3.3,1.9,1.9,0,0,0,2.2.1,2,2,0,0,0,1.3-1.6c.1-.7-.3-1.8-1.1-3.5C658.4,819.1,656.7,815.5,653.3,808.4Z" transform="translate(-41.9 -130.4)"/>
            <path d="M678.3,781l2.5,5.1c-.3-4.6,1.2-8.1,4.5-10.6,3.4,5.1,5,7.6,8.4,12.6a16.1,16.1,0,0,0-4.8,4.3,4.1,4.1,0,0,0-.6,3.6c.3,1.2,1.6,3.5,3.8,7l9,14.4c-5.7,3.6-8.7,5.4-14.5,8.7l-21.1-37.5C670.7,785.7,673.3,784.2,678.3,781Z" transform="translate(-41.9 -130.4)"/>
            <path d="M709.8,746.2l34,39.8c-5.3,4.6-8,6.8-13.4,11l-1.9-2.5a17,17,0,0,1-2.4,5.5,14.2,14.2,0,0,1-4,4.1,13.7,13.7,0,0,1-6.5,2.8,7.6,7.6,0,0,1-5.2-1,21.1,21.1,0,0,1-4.5-5.2L694.6,784a19.8,19.8,0,0,1-3.3-6.2,6.9,6.9,0,0,1,.7-4.9,11.9,11.9,0,0,1,4.1-4.9,15.4,15.4,0,0,1,9.7-2.9l-7.5-9.6C703,751.9,705.3,750,709.8,746.2Zm.9,25.3a8.7,8.7,0,0,0-2.3-2.2,2.3,2.3,0,0,0-2,.5,2.6,2.6,0,0,0-1.1,1.7,7.8,7.8,0,0,0,1.6,2.9l12.7,16.9a6.5,6.5,0,0,0,2.4,2.4c.6.3,1.3.1,2.1-.5a2.2,2.2,0,0,0,1.1-2c-.1-.7-.8-2-2.3-3.9Z" transform="translate(-41.9 -130.4)"/>
          </g>
        </svg>
      </div>
      <div className="container">
        <h1>Unreal Discord</h1>

        <Optin />
        <Preloader />
      </div>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);

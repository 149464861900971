import React, { Component } from "react";
import "./Optin.css";
import { FaFacebook, FaYoutube, FaGithub, FaInstagram } from "react-icons/fa";

class Optin extends Component {
  modal() {
    const modal = document.getElementById("modal");
    modal.classList.toggle("is_open");
  }

  render() {
    return (
      <div className="optin">
        <a href="https://www.discord.gg/Yd7uEhtxDz">
          <button className="">Join US</button>
        </a>
        <p>
        <b>
          Unreal Discord Community, We Talk Unreal things. Games and Films development using Unreal Engine, Unity Engine, Cry Engine, Maya, 3DsMax and Blender.
        </b>
        </p>
        <div className="media">
          <div className="col-4">
            <a href="https://www.facebook.com/morein4">
              <FaFacebook color="blue" className="icon" />
            </a>
          </div>
          <div className="col-4">
            <a href="https://www.youtube.com/morein4">
              <FaYoutube color="red" className="icon" />
            </a>
          </div>
          <div className="col-4">
            <a href="https://www.Github.com/morein4">
              <FaGithub color="green" className="icon" />
            </a>
          </div>
          <div className="col-4">
            <a href="https://www.Instagram.com/morein4">
              <FaInstagram color="purple" className="icon" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Optin;
